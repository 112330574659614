.content-container {
  width: calc(100% - (40px + 224px + 40px));
  background-color: rgb(244, 244, 244);
  padding: 40px 40px 40px calc(224px + 40px);
  flex-grow: 1;
}

.preview-layout {
  /* background-color: #F9F9F9; */
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  row-gap: 80px;
}

/* About */

.about .right > h2 {
  width: 376px;
}

.about .img-label-container {
  width: 100%;
  opacity: 0;
  transform: translateY(12px);
}

.img-label-container-loaded {
  opacity: 1 !important;
  transform: translateY(0) !important;
  transition: opacity 0.5s linear, transform 0.5s linear;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.section-info--item {
  display: flex;
  justify-content: space-between;
}

.section-info--item--content {
  width: 56%;
}


/* General case study layout */

.case-study, .about {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.right .mockup {
  width: 32%;
  opacity: 0.8;
}

.row {
  display: grid;
  grid-template-columns: 360px minmax(400px, 800px);
  column-gap: 144px;
  /* column-gap: 16%; */
}

.first-p {
  margin-top: 24px;
}

.first-p--0 {
  margin-top: 0;
}

.first-p--16 {
  margin-top: 16px;
}

.last-p {
  margin-bottom: 28px;
}

.last-p--0 {
  margin-bottom: 0px;
}

.last-p--48 {
  margin-bottom: 48px;
}

.round-img {
  width: 100%;
  border-radius: 0 16px 16px 16px;
  border: 0.5px solid #DADADA;
}

.ms-onboarding .round-img,
.sm .round-img {
  opacity: 0.8;
}

.img-label-container {
  display: flex;
  flex-direction: column;
  gap: 0px;
  flex: 1;
}

/* images in img-label-containers that aren't in round-img divs mess up when resizing */
.ms-onboarding .row-16 img,
.notability .row-6 img,
.notability .row-8 img,
.notability .row-11 img {
  width: 100%;
}

.row-1 > .left {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-shrink: 0;
  width: 360px;
}

.row-1 > .right {
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.summary {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.summary > * {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}

.summary h3, .summary p {
  margin: 0;
}

.summary h3 {
  width: 120px;
}

.summary p {
  width: 72%;
}

.summary .copy {
  display: flex;
  flex-direction: column;
  width: 72%;
}

.summary .copy p {
  width: 100%;
}

.summary .copy p:not(:last-child) {
  margin-bottom: 16px;
}

.summary .copy ul {
  margin-top: 0;
  margin-bottom: 16px;
}

.row-0--container,
.row-0,
.notability .row-4,
.notability .row-8,
.notability .row-10,
.notability .row-14,
.notability .row-16,
.notability .row-18,
.ms-onboarding .row-8, 
.ms-onboarding .row-10 {
  width: 100%;
  max-width: calc(360px + 128px + 800px);
}

.ms-onboarding .row:not(.row-0--container):not(.row-0-text),
.notability .row:not(.row-0--container):not(.row-0-text),
.sm .row:not(.row-0):not(.row-0-text),
:not(.ms-onboarding):not(.sm):not(.notability) > .row:not(.row-1) {
  margin-top: 272px;
}

.row-0 {
  opacity: 0;
  width: 100%;
  aspect-ratio: 16/8;
  border-radius: 0 24px 24px 24px;
  overflow: hidden;
  transition: opacity 0.6s linear;
}

.ms-onboarding .row-0,
.sm .row-0 {
  display: flex;
  gap: 4%;
  justify-content: center;
}

.ms-onboarding .row-0 img.one, 
.ms-onboarding .row-0 img.three,
.sm .row-0 img.one, 
.sm .row-0 img.three {
  opacity: 0;
  transform: translateY(0);
  transition: opacity 0.6s linear,
              transform 1s ease-out;
}

.ms-onboarding .row-0 img.two,
.sm .row-0 img.two {
  opacity: 0;
  transform: translateY(0);
  transition: opacity 0.6s linear,
              transform 1s ease-out;
}

.ms-onboarding .row-0--loaded img.one, 
.ms-onboarding .row-0--loaded img.three,
.sm .row-0--loaded img.one, 
.sm .row-0--loaded img.three {
  transform: translateY(-14%);
}

.ms-onboarding .row-0--loaded img.two, 
.sm .row-0--loaded img.two {
  transform: translateY(24%);
}

.ms-onboarding .row-0 img,
.sm .row-0 img {
  width: 22%;
  height: auto;
  object-fit: contain;
  z-index: 2;
}

.row-0-text {
  margin-top: 40px;
}

.row-0-text .right {
  max-width: 96%;
}

.texture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
  z-index: 1;
  mix-blend-mode: normal;
  opacity: 0;
}

.title-divider {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.divider {
  margin: 0;
  width: 100%;
  background-color: #DADADA;
  height: 0.5px;
}

.ms-onboarding .row-1 > .right > .mockups,
.sm .row-1 > .right > .mockups {
  display: flex;
  align-items: flex-start;
  gap: 24px;
 }

.ms-onboarding .row-1 > .right > .mockups > .first,
.ms-onboarding .row-1 > .right > .mockups > .third,
.sm .row-1 > .right > .mockups > .first,
.sm .row-1 > .right > .mockups > .third {
  margin-top: 120px;
}



/* Notability */

.notability .row-0 {
  background: radial-gradient(ellipse closest-side, rgba(94, 98, 107, 0) 64%, rgba(94, 98, 107, 0.16) 100%),
              linear-gradient(180deg, #f9f9f9 0%, #ECF2FF 56%, #7183A0 100%);
  position: relative;
}

.notability .row-0--loaded {
  opacity: 1;
}

.notability .texture {
  transition: opacity 1s linear;
}

.notability .texture--loaded {
  opacity: 1;
  mix-blend-mode: darken;
}

.notability .row-0 img {
  z-index: 2;
  width: 48%;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
}

.notability .row-0 img.one {
  width: 72%;
  transform:  translate(-50%, 25%);
  transform-origin: 50% 0;
  -moz-transition: opacity 0.6s linear 0.8s,
                   -moz-transform 0.6s ease-in-out 0.8s,
                   -moz-width 0.6s ease-in-out 0.8s;
  -ms-transition: opacity 0.6s linear 0.8s,
                  -ms-transform 0.6s ease-in-out 0.8s,
                  -ms-width 0.6s ease-in-out 0.8s;
  -o-transition: opacity 0.6s linear 0.8s,
                  -o-transform 0.6s ease-in-out 0.8s,
                  -o-width 0.6s ease-in-out 0.8s;
  -webkit-transition: opacity 0.6s linear 0.8s,
                      -webkit-transform 0.6s ease-in-out 0.8s;
                      /* -webkit-width 0.6s ease-in-out 0.8s; */
  transition: opacity 0.6s linear 0.8s,
                transform 0.6s ease-in-out 0.8s,
                width 0.6s ease-in-out 0.8s;
}

.notability .row-0 img.two {
  width: 58%;
  transform:  translate(-50%, 25%);
  -moz-transition: opacity 0.6s linear 0.4s,
                   -moz-transform 0.8s ease-in-out 0.4s,
                   -moz-width 0.8s ease-in-out 0.4s;
  -ms-transition: opacity 0.6s linear 0.4s,
                  -ms-transform 0.8s ease-in-out 0.4s,
                  -ms-width 0.8s ease-in-out 0.4s;
  -o-transition: opacity 0.6s linear 0.4s,
                  -o-transform 0.8s ease-in-out 0.4s,
                  -o-width 0.8s ease-in-out 0.4s;
  -webkit-transition: opacity 0.6s linear 0.4s,
                      -webkit-transform 0.8s ease-in-out 0.4s;
                      /* -webkit-width 0.8s ease-in-out 0.4s; */
  transition: opacity 0.6s linear 0.4s,
              transform 0.8s ease-in-out 0.4s,
              width 0.8s ease-in-out 0.4s;
}

.notability .row-0 img.three {
  transform: translate(-50%, 25%);
  -moz-transition: opacity 0.6s linear,
                   -moz-transform 1s ease-in-out,
                   -moz-width 1s ease-in-out;
  -ms-transition: opacity 0.6s linear,
                  -ms-transform 1s ease-in-out,
                  -ms-width 1s ease-in-out;
  -o-transition: opacity 0.6s linear,
                  -o-transform 1s ease-in-out,
                  -o-width 1s ease-in-out;
  -webkit-transition: opacity 0.6s linear,
                      -webkit-transform 1s ease-in-out;
                      /* -webkit-width 1s ease-in-out; */
  transition: opacity 0.6s linear,
              transform 1s ease-in-out,
              width 1s ease-in-out;
}

/* SAFARI FIX */

.safari.notability .row-0 img.one {
  -webkit-transition: opacity 0.6s linear 0.8s, -webkit-transform 0.6s ease-in-out 0.8s;
  transition: opacity 0.6s linear 0.8s,
              transform 0.6s ease-in-out 0.8s; /* Exclude width transition for Safari */
}

.safari.notability .row-0 img.two {
    -webkit-transition: opacity 0.6s linear 0.4s,
                      -webkit-transform 0.8s ease-in-out 0.4s;
    transition: opacity 0.6s linear 0.4s,
              transform 0.8s ease-in-out 0.4s;
}

.safari.notability .row-0 img.three {
  -webkit-transition: opacity 0.6s linear,
                      -webkit-transform 1s ease-in-out;
  transition: opacity 0.6s linear,
              transform 1s ease-in-out;
  }

/* SAFARI FIX */

.notability .row-0--loaded img.one {
  opacity: 0.98;
  width: 52%;
  transform:  translate(-50%, -17%);
}

.notability .row-0--loaded img.two {
  opacity: 0.98;
  width: 38%;
  transform: translate(-50%, -51%);
}

.notability .row-0--loaded img.three {
  opacity: 0.98;
  width: 28%;
  transform: translate(-50%, -96%);
}

.notability .row-4 img {
  width: 100%;
  height: auto;
}

.notability .row-6 img {
  opacity: 0.8;
}

.notability .row-8 {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.notability .row-8 .youtube-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  transform-style: preserve-3d;
}

.notability .row-8 .youtube-container img {
  max-width: calc(25% - 16px);
  height: auto;
  flex: 1 0 0;
}

.notability .row-8 .youtube-1 {
  animation: disappear1 6s infinite ease-out 1s;
}

.notability .row-8 .youtube-2 {
  animation: disappear2 6s infinite ease-out 1s;
}

.notability .row-8 .youtube-3 {
  animation: disappear3 6s infinite ease-out 1s;
}

.notability .row-8 .youtube-4 {
  animation: disappear4 6s infinite ease-out 1s;
}

@-webkit-keyframes disappear1 {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
    opacity: 1;
  }

  30% {
    -webkit-transform: scale(0.25) translateY(600%) translateX(450%);
    /* -webkit-transform: scale(1) translateY(600%) translateX(450%); */
    opacity: 0;
  }
  
  30.01% {
    -webkit-transform: scale(1) translate(0, 0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1) translate(0, 0);
    opacity: 1;
  }
}

@-moz-keyframes disappear1 {
  0% {
    -moz-transform: scale(1) translate(0, 0);
    opacity: 1;
  }

  30% {
    -moz-transform: scale(0.25) translateY(600%) translateX(450%);
    /* -moz-transform: scale(1) translateY(600%) translateX(450%); */
    opacity: 0;
  }
  
  30.01% {
    -moz-transform: scale(1) translate(0, 0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -moz-transform: scale(1) translate(0, 0);
    opacity: 1;
  }
}

@keyframes disappear1 {
  0% {
    transform: scale(1) translate(0, 0);
    opacity: 1;
  }

  30% {
    transform: scale(0.25) translateY(600%) translateX(450%);
    /* transform: scale(1) translateY(600%) translateX(450%); */
    opacity: 0;
  }
  
  30.01% {
    transform: scale(1) translate(0, 0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1) translate(0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes disappear2 {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
    opacity: 1;
  }

  20% {
    -webkit-transform: scale(1) translate(0, 0);
    opacity: 1;
  }

  50% {
    -webkit-transform: scale(0.25) translateY(650%) translateX(150%);
    opacity: 0;
  }

  50.01% {
    -webkit-transform: scale(1) translate(0, 0);
    opacity: 0;
  }

  75% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1) translate(0, 0);
    opacity: 1;
  }
}

@-moz-keyframes disappear2 {
  0% {
    -moz-transform: scale(1) translate(0, 0);
    opacity: 1;
  }

  20% {
    -moz-transform: scale(1) translate(0, 0);
    opacity: 1;
  }

  50% {
    -moz-transform: scale(0.25) translateY(650%) translateX(150%);
    opacity: 0;
  }

  50.01% {
    -moz-transform: scale(1) translate(0, 0);
    opacity: 0;
  }

  75% {
    opacity: 1;
  }

  100% {
    -moz-transform: scale(1) translate(0, 0);
    opacity: 1;
  }
}


@keyframes disappear2 {
  0% {
    transform:  scale(1) translate(0, 0);
    opacity: 1;
  }

  20% {
    transform:  scale(1) translate(0, 0);
    opacity: 1;
  }

  50% {
    transform: scale(0.25) translateY(650%) translateX(150%);
    opacity: 0;
  }

  50.01% {
    transform:  scale(1) translate(0, 0);
    opacity: 0;
  }

  75% {
    opacity: 1;
  }

  100% {
    transform:  scale(1) translate(0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes disappear3 {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
    opacity: 1;
  }

  40% {
    -webkit-transform: scale(1) translate(0, 0);
    opacity: 1;
  }

  75% {
    -webkit-transform: scale(0.25) translateY(650%) translateX(-150%);
    opacity: 0;
  }

  75.01% {
    -webkit-transform: scale(1) translate(0, 0);
    opacity: 0;
  }

  99.9% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1) translate(0, 0);
    opacity: 1;
  }
}

@-moz-keyframes disappear3 {
  0% {
    -moz-transform: scale(1) translate(0, 0);
    opacity: 1;
  }

  40% {
    -moz-transform: scale(1) translate(0, 0);
    opacity: 1;
  }

  75% {
    -moz-transform: scale(0.25) translateY(650%) translateX(-150%);
    opacity: 0;
  }

  75.01% {
    -moz-transform: scale(1) translate(0, 0);
    opacity: 0;
  }

  99.9% {
    opacity: 1;
  }

  100% {
    -moz-transform: scale(1) translate(0, 0);
    opacity: 1;
  }
}

@keyframes disappear3 {
  0% {
    transform:  scale(1) translate(0, 0);
    opacity: 1;
  }

  40% {
    transform:  scale(1) translate(0, 0);
    opacity: 1;
  }

  75% {
    transform: scale(0.25) translateY(650%) translateX(-150%);
    opacity: 0;
  }

  75.01% {
    transform:  scale(1) translate(0, 0);
    opacity: 0;
  }

  99.9% {
    opacity: 1;
  }

  100% {
    transform:  scale(1) translate(0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes disappear4 {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
    opacity: 1;
  }

  60% {
    -webkit-transform: scale(1) translate(0, 0);
    opacity: 1;
  }

  99.9% {
    -webkit-transform: scale(0.25) translateY(600%) translateX(-450%);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1) translate(0, 0);
    opacity: 1;
  }
}

@-moz-keyframes disappear4 {
  0% {
    -moz-transform: scale(1) translate(0, 0);
    opacity: 1;
  }

  60% {
    -moz-transform: scale(1) translate(0, 0);
    opacity: 1;
  }

  99.9% {
    -moz-transform: scale(0.25) translateY(600%) translateX(-450%);
    opacity: 0;
  }

  100% {
    -moz-transform: scale(1) translate(0, 0);
    opacity: 1;
  }
}

@keyframes disappear4 {
  0% {
    transform:  scale(1) translate(0, 0);
    opacity: 1;
  }

  60% {
    transform:  scale(1) translate(0, 0);
    opacity: 1;
  }

  99.9% {
    transform: scale(0.25) translateY(600%) translateX(-450%);
    opacity: 0;
  }

  100% {
    transform:  scale(1) translate(0, 0);
    opacity: 1;
  }
}

.notability .row-15 {
  margin-top: 48px !important;
}

/* MS-Onboarding */

.ms-onboarding .row-0 {
  background: linear-gradient(180deg, #f9f9f9 9%, #82897E 70%, #f9f9f9 100%);
}

.ms-onboarding .row-0--loaded  {
  opacity: 1;
}

.row-0--container {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 0 24px 24px 24px;
  box-shadow: 0 0 24px 2px rgba(0, 0, 0, 0.12);
}


.ms-onboarding .texture {
  transition: opacity 8s linear
}

.ms-onboarding .texture--loaded {
  opacity: 0.8;
  mix-blend-mode: difference;
}

.ms-onboarding .row-0--loaded img.one, .ms-onboarding .row-0--loaded img.three {
  opacity: 0.88;
}

.ms-onboarding .row-0--loaded img.two {
  opacity: 0.6;
}

.ms-onboarding .row-3 .right .mockups, 
.ms-onboarding .row-12 .right .mockups, 
.ms-onboarding .row-15 .right .mockups {
  display: flex;
  gap: 24px;
  margin-top: 48px;
}

.ms-onboarding .row-3 .img-label-container .img-label {
  width: 80%;
}

/* .ms-onboarding .row-3 .right .mockup {
  width: 40%; 
} */

.ms-onboarding .row-4 img {
  width: 100%;
  opacity: 0.8;
}

.waitlist-container {
  display: flex;
  gap: 16px;
  align-items: flex-end;
}

.waitlist-container img {
  width: 100%;
  opacity: 0.8;
}

.ms-onboarding .row-8 img, 
.ms-onboarding .row-10 img {
  width: 100%;
  height: auto;
}

.ms-onboarding .row.row-9 {
  margin-top: 48px !important;
  margin-bottom: 48px;
}

.registration-difference__container {
  position: relative;
}

.registration-difference__container > .arrow {
  opacity: 0.6;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.registration-difference {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.registration-problem__header img, .registration-solution__header img {
  width: 24px;
  height: auto;
}

.registration-problem {
  background: rgba(229, 70, 70, 0.10);
  border-radius: 0px;
  border: 0.5px solid #DADADA;
  border-bottom: none;
  border-right: none;
  padding: 10% 8% 8% 8%;
}

.registration-problem__header {
  display: flex;
  gap: 8px;
  align-items: flex-start;
}

.registration-solution {
  background: rgba(101, 180, 87, 0.10);
  border-radius: 0px 16px 0px 0px;
  border: 0.5px solid #DADADA;
  border-bottom: none;
  padding: 10% 8% 8% 8%;
}

.registration-solution__header {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  height: 48px;
}

.registration-difference__content {
  padding-top: 8px;
  padding-left: calc(24px + 8px);
  position: relative;
}

.registration-problem__mockup {
  background: rgba(229, 70, 70, 0.10);
  border-radius: 0 0 0 16px;
  border: 0.5px solid #DADADA;
  border-top: none;
  border-right: none;
  padding: 0px 8% 10% calc(6% + 24px + 8px);
}

.registration-solution__mockup {
  background: rgba(101, 180, 87, 0.10);
  border-radius: 0px 0px 16px 0px;
  border: 0.5px solid #DADADA;
  border-top: none;
  padding: 0px 8% 10% calc(6% + 24px + 8px);
}

.registration-problem__mockup .mockup, .registration-solution__mockup .mockup {
  width: 88% !important; 
}

.ms-onboarding .row-11 .mockup {
  width: 32% !important;
  opacity: 0.8;
}

.revamp-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
}

.revamp-problems, .revamp-solutions {
  width: 100%;
  padding: 40px 0px 40px 0px;
  border: 0.5px solid #DADADA;
  display: flex;
  justify-content: center;
}

.revamp-problems {
  background: rgba(229, 70, 70, 0.10);
  border-radius: 0 16px 0 0;
  border-bottom: none;
}

.revamp-problems img, .revamp-solutions img {
  width: 90%;
}

.revamp-solutions {
  border-radius: 0 0 16px 16px;
  background: rgba(101, 180, 87, 0.10);
  border-top: none;
}

.revamp-grid {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  border: 0.5px solid #DADADA;
  border-bottom: none;
}

.revamp-grid__problem {
  border-right: 0.5px solid #DADADA;
  background: rgba(229, 70, 70, 0.10);

}

.revamp-grid__solution {
  background: rgba(101, 180, 87, 0.10);
}

.revamp-grid__problem, .revamp-grid__solution {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  padding-left: 16px;
  border-bottom: 0.5px solid #DADADA;
}

.revamp-grid p {
  max-width: 224px;
}

.revamp-grid img {
  width: 24px;
  height: auto;
}

.ms-onboarding .row-14 .right {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  row-gap: 48px;
}

.mockup-gif-container {
  width: 100%;
  position: relative;
}

.gif-label {
  display: flex;
  flex-direction: column;
  min-width: 96px;
  width: 40%;
  position: absolute;
  top: 32%;
  backdrop-filter: blur(2px);
}

.gif-label img, .gif-label__text {
  width: 100%;
}

.gif-label img {
  border-radius: 0 16px 0 0;
  border: 0.5px solid #DADADA;
  border-bottom: none;
  opacity: 0.88;
}

.gif-label__text {
  white-space: nowrap;
  display: flex;
  justify-content: center;
  background: rgba(233, 250, 226, 0.72);
  border-radius: 0 0 8px 8px;
  border: 0.5px solid #DADADA;
}

.gif-label__text p {
  margin: 8px 0 8px 0;
}

.ms-onboarding .row-14 .right .mockup {
  width: 64%;
  opacity: 0.4;
  margin-left: 24%;
}

.ms-onboarding .row-17 img {
  width: 100%;
}

/* Supply Me */

.sm .row-0 {
  background: linear-gradient(180deg, #889AFF 0%, #707CFF 37%, #F2F4FF 100%);
  box-shadow: 0 0 24px 2px rgba(0, 0, 0, 0.12);
}

.sm .row-0--loaded  {
  opacity: 0.96;
}

.sm .row-0--loaded img {
  opacity: 0.88 !important;
}

.sm .img-label-container .iphone {
  width: 100%;
}

.sm .row-1 .left {
  width: 240px;
}

.sm .row-6 img {
  width: 100%;
}

.sm .row-7 .left .img-label-container img:first-child {
  margin-bottom: 24px;
}

.sm-numbered {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.sm-numbered img {
  opacity: 0.8;
}

.sm .row-8 .right p, 
.sm .row-8 .right .img-label-container > img,
.sm .row-10 .right p,
.sm .row-10 .right ul,
.sm .row-10 .right ol,
.sm .row-10 .right .mockup,
.sm .row-10 .right .img-label-container > img,
.sm .row-12 .right p,
.sm .row-12 .right .img-label-container > img {
  margin-left: 40px;
}

.sm .row-8 .right .img-label-container > img {
  opacity: 0.8;
}

.sm .row-8 .right .img-label-container > img {
  margin-top: 16px;
  width: calc(100% - 48px);
}

.sm .row-10 .right .img-label-container > img,
.sm .row-12 .right .img-label-container > img {
  width: calc(100% - 48px);
}

.sm .row-10 .left > img, 
.sm .row-10 .right > img {
  width: 100%;
}


/* WREK Design Collective */

.wdc .right > img, .wdc .right video {
  width: 100%;
  height: auto;
}

.wdc .right a > video {
  width: 100%;
  height: 100%;
  display: flex;
}

.wdc .row-1 .right {
  gap: 24px;
}

.wdc .summary {
  margin-top: 0 !important;
}

.wdc .right:not(.row-1 .right) {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.column-grid--2 {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.column-grid--2 img {
  width: 100%;
}

/* 8-Ball-Zines */

.eight-ball-zines img {
  width: 100%;
  height: auto;
}

.eight-ball-zines .row-1 .right {
  gap: 24px;
}

.eight-ball-zines .summary {
  margin-top: 0 !important;
}

.eight-ball-zines .row-3 {
  display: flex;
  flex-direction: column;
  gap: 24px;
}


/* Responsive layouts */

@media screen and (max-width: 1160px) {
  .row {
    grid-template-columns: 360px minmax(300px, 400px);
    column-gap: 40px;
  }
}

@media screen and (max-width: 1024px) {

  /* General layout */

  .content-container {
    width: calc(100% - (40px + 72px));
    padding: 40px 40px 40px 72px;
  }

  .row {
    grid-template-columns: 600px;
  }

  .row-0-text > .right {
    margin-top: 40px;
  }

  .ms-onboarding .row-13 > .right, 
  .sm .row-5 .right {
    margin-top: 272px;
  }
  
  .notability .row-2 > .right,
  .notability .row-3 > .right,
  .notability .row-5 > .right,
  .notability .row-6 > .right,
  .notability .row-7 > .right,
  .notability .row-12 > .right,
  .notability .row-13 > .right,
  .notability .row-17 > .right,
  .ms-onboarding .row-11 > .right, 
  .ms-onboarding .row-2 > .right,
  .ms-onboarding .row-7 > .right,  
  .ms-onboarding .row-17 .right,
  .waitlist-container, 
  .sm .row-6 .right,
  .sm .row-8 .right,
  .sm .row-9 .right,
  .sm .row-10 .right,
  .sm .row-12 .right,
  .wdc .row-1 .right,
  .wdc .row-2 .right,
  .wdc .row-3 .right, 
  .wdc .row-4 .right,
  .wdc .row-5 .right,
  .wdc .row-6 .right,
  .eight-ball-zines .row-1 .right {
    margin-top: 24px;
  }

  .wdc .row-1 .left,
  .eight-ball-zines .row-1 .left {
    width: 100%;
  }

  .eight-ball-zines .row-2 {
    margin-top: 80px;
  }

  .notability .row-8, .youtube-container {
    width: 600px !important;
  }

  .img-label-container {
    width: 600px !important;
  }

  .mockup-gif-container {
    margin-top: 48px;
  }

}

/* 600 + 40px left + 72px right = 712 */
@media screen and (max-width: 712px) {
  .row {
    grid-template-columns: 1fr;
  }

  .img-label-container,
  .notability .row-8, .youtube-container {
    width: 100% !important;
  }
}

@media screen and (max-width: 480px) {

.row-1 .left {
  width: 100%;
}

.about .row-1 .right h2 {
  width: 100%;
}

.preview-layout {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  /* column-gap: 40px; */
  row-gap: 80px;
}

}

/* Test Page */

.ipad {
  height: auto;
  aspect-ratio: calc(500 / 358.42);
  position: relative;
}

.ipad > * {
  position: absolute;
}

.ipad .ipad-device {
  width: 100%;
  height: auto;
}

.ipad .screen {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}