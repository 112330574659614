.sidebar--visible, .sidebar--hidden {
    background: rgba(255, 255, 255, 0.80);
    backdrop-filter: blur(4px);
    top: 0;
    left: 0;
    width: 224px;
    height: 100vh;
    border-right: 0.5px solid #DADADA;
    scrollbar-color: #314321 white;
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 999;
}

.sidebar--visible {
     opacity: 1;
     transition: transform 0.5s cubic-bezier(0.2, 0, 0.8, 1), opacity 0.5s ease-in;
}

.name-container {
    height: 40px;
    display: inline-flex;
    gap: 8px;
    align-items: center;
    padding-left: 16px;
    border-bottom: 0.5px solid #DADADA;
    flex-shrink: 0;
}

.name-container.with-img {
    padding-left: 12px;
}

.name-container > img {
    cursor: pointer;
}

.nav-container {
    display: flex;
    flex-direction: column;
    gap: 64px;
    padding: 64px 0;
    padding-left: 16px;
    scrollbar-color: #314321;
    overflow-y: auto; 
}

nav > ul > li {
    list-style-type: none;
}

/* Remove weird indent spacing in list */
nav > ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

nav a {
    text-decoration: none;
} 

.active {
    display: flex;
    padding: 6px 6px;
    border-radius: 4px;
    border: 0.5px solid #314321;
    background: #F9F9F9;
    width: fit-content;
    scale: 1;
    transition: padding-left ease-out 0.3s, padding-right ease-out 0.5s;
}

nav a:not(.active) {
    opacity: 0.4;
    /* filter: blur(1px); */
    transition: opacity ease-in-out 0.3s, filter ease-in-out 0.3s;
}

nav a:not(.active):hover {
    opacity: 1;
    filter: blur(0px);
}

.bio {
    width: 168px;
}

.contact {
    display: flex;
    gap: 16px;
}

/* .contact > a {
    filter: blur(0.75px);
    transition: filter ease-in-out 2s;
}

.contact > a:hover {
    filter: blur(0px);
    transition-duration: 0.6s;
} */

/* Collapsed Sidebar */

.toggle-overlay {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(214, 236, 195, 0.40);
    backdrop-filter: blur(0.5px);
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s ease-in;
    cursor: pointer;
}

.toggle-overlay.hidden {
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease-in;
    cursor: auto;
}

.burger-icon {
    position: fixed;
    top: 8px;
    left: 8px;
    z-index: 2;
    cursor: pointer;
    opacity: 1;
    transform: translateY(0%);
    transition: transform 0.5s ease-out, opacity 0.5s ease-out;
}

@keyframes appear {
    0% {
        transform: translateY(30%);
    }
    100% {
        transform: translateY(0%);
    }
}

.burger-icon.hidden {
    opacity: 0;
    transform: translateY(30%);
    transition: opacity 0.3s ease-in, transform 1s ease-in;
}

.sidebar--hidden {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 0.5s cubic-bezier(0.2, 0, 0.8, 1), opacity 0.5s ease-in;
}
