.preview {
    width: 100%;
    /* background-color: #F9F9F9; */
    /* background-color: black; */
    display: flex;
    flex-direction: column;
    gap: 24px;    
}

.preview-image-container {
    z-index: 999;
    --border-width: 0.5px;
    --radius: 4.28%;
    position: relative;
    aspect-ratio: 3.25/3;
    border-radius: 0 var(--radius) var(--radius) var(--radius);
    transition: box-shadow 0.5s ease-in-out;
}

.preview-image-container:hover {
    box-shadow: 0 0 16px 2px rgba(0, 0, 0, 0.16);
}


.preview-image {
    --border-width: 0.5px;
    --radius: 4.28%;
    position: absolute;
    top: 0;
    left: 0;
    /* opacity: 0.5; */
    width: 100%; /* change later, depends on desktop width */
    height: 100%;
    aspect-ratio: 3.25/3; 
    /* aspect-ratio: 1/1; */
    border-radius: 0 var(--radius) var(--radius) var(--radius);
    /* border: var(--border-width) solid transparent; */
    border: var(--border-width) solid #DADADA;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
}

.preview-image:hover {
    opacity: 1;
}

.preview-image::after {
    content: "";
    position: absolute;
    border-radius: inherit;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 0.5px solid #DADADA; 
    inset: -1px;
}

.preview-image:hover::after {
    display: none;
}

.preview-image::before {
    content: " ";
    position: absolute;
    inset: calc(var(--border-width) * -1);
    z-index: -1;
    border: inherit;
    border-radius: inherit;
    background-image: conic-gradient(from var(--angle), #DADADA 100%, #DADADA 100%);
    background-origin: border-box;
    -webkit-mask:
        linear-gradient(black, black) content-box,
        linear-gradient(black, black);
    mask: linear-gradient(black, black),
        linear-gradient(black, black);
    -webkit-mask-clip: content-box, border-box;
    mask-clip: content-box, border-box;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    animation: spin 3s linear infinite;
    animation-play-state: paused;
}

@supports not (background: paint(something)) {
    .preview-image::before {
      background-image: conic-gradient(#381D6A 70%, #E0D1FF 80%, #E0D1FF 92%, #381D6A 100%);
    }
}

.preview-image:hover::before {
    border: 1.5px solid transparent;
    /* background-image: conic-gradient(from var(--angle), #F4F4F4 80%, #5A694D 88%, #314321 92%, #F4F4F4 100%); */
    background-image: conic-gradient(from var(--angle), #DADADA 80%, #5A694D 88%, #314321 92%, #DADADA 100%);
    animation-play-state: running;
}
  
@property --angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
  }
  
@keyframes spin {
    to {
      --angle: 1turn;
    }
  }


.preview-image.ms-onboarding-preview {
    background: linear-gradient(calc(245deg), rgba(49, 67, 33, 0.5) -61.85%, rgba(241, 241, 241, 0.00) 68.73%);
}

.preview-image-container:hover .ms-onboarding-preview {
    background: linear-gradient(calc(245deg), #314321 -61.85%, rgba(241, 241, 241, 0.00) 68.73%);
}

.preview-image.notability-preview {
    background: linear-gradient(135deg, rgba(49, 67, 33, 0.5) -80.85%, rgba(241, 241, 241, 0.00) 68.73%);
}

.preview-image-container:hover .notability-preview {
    background: linear-gradient(135deg, #314321 -61.85%, rgba(241, 241, 241, 0.00) 68.73%);
}

.preview-image.ms-social-preview {
    background: linear-gradient(65deg, rgba(49, 67, 33, 0.5) -71.85%, rgba(241, 241, 241, 0.00) 80.73%);
}

.preview-image-container:hover .ms-social-preview {
    background: linear-gradient(65deg, #314321 -71.85%, rgba(241, 241, 241, 0.00) 80.73%);
}

.preview-image.wdc-preview {
    background: linear-gradient(245deg, rgba(49, 67, 33, 0.5) -121.85%, rgba(241, 241, 241, 0.00) 80.73%);
}

.preview-image-container:hover .wdc-preview {
    background: linear-gradient(245deg, #314321 -121.85%, rgba(241, 241, 241, 0.00) 80.73%);
}

.preview-image.sm-preview {
    background: linear-gradient(245deg, rgba(49, 67, 33, 0.5) -321.85%, rgba(241, 241, 241, 0.00) 80.73%);
}

.preview-image-container:hover .sm-preview {
    background: linear-gradient(245deg, #314321 -321.85%, rgba(241, 241, 241, 0.00) 80.73%);
}

.title-divider {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.divider {
    margin: 0;
    width: 100%;
    background-color: #DADADA;
    height: 0.5px;
}

.preview-caption {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.preview h1 {
    line-height: 16px;
    margin: 0;
}

.preview-caption h3 {
    width: 56px;
    margin: 0;
}

.preview-caption p {
    width: 72%;
    margin: 0;
}

.preview-caption-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    white-space: pre-line;
}

.mobile-prototypes .mockup {
    width: 36%;
}

/* Specific preview CSS */

.mobile-prototypes {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 24px;
    opacity: 0;
    /* transition: opacity 0.6s ease-in-out;  */
}

.mobile-prototypes--loaded {
    opacity: 0.3;
    transition: opacity 0.5s ease-in-out;
}

/* MS Onboarding & Supply Me */

.ms-onboarding-preview .mobile-prototypes .first,
.sm-preview .mobile-prototypes .first {
    transform:
        perspective(1200px)
        translateZ(-80px)
        translateY(-32%);
    transition: transform 0.6s cubic-bezier(0.7, -0.4, 0.4, 1.4);
}

.ms-onboarding-preview .mobile-prototypes .second,
.sm-preview .mobile-prototypes .second  {
    transform:
        perspective(1200px)
        translateZ(-80px)
        translateY(32%);
    transition: transform 0.6s cubic-bezier(0.7, -0.4, 0.4, 1.4);
}

.ms-onboarding-preview .mobile-prototypes:hover,
.sm-preview .mobile-prototypes:hover {
    opacity: 0.9;
}

/* MS Social - note, the 19px value was determined through trial-and-error since the translateZ affected the X position as well  */

/* .preview-image-container:hover .ms-social-preview {
    cursor: default !important;
} */

.ms-social-preview .mobile-prototypes .first {
    z-index: 2;
    transform:
        perspective(1200px)
        translateZ(-80px)
        translateX(calc(50% + 19px));
        /* translate(100%, 0); */
    transition: transform 0.6s cubic-bezier(0.7, -0.4, 0.4, 1.4);
    /* transition: transform 0.6s ease-in-out; */
}

.ms-social-preview .mobile-prototypes .second {
    opacity: 0;
    z-index: 1;
    transform:
        perspective(1200px)
        translateZ(-80px)
        translateX(calc(-50% - 19px));
    /* transition: transform 0.6s cubic-bezier(0.7, -0.4, 0.4, 1.4), */
    transition: transform 0.65s ease-in-out, 
    opacity 0.3s linear;
}

/* Resets all mobile prototypes to original position */
.mobile-prototypes:hover .first,
.mobile-prototypes:hover .second {
    transform: 
    translateY(0);
    opacity: 0.9;
}

/* iPad & Notability */

.ipad-prototypes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    width: 100%;
    opacity: 0;
}

.ipad-prototypes--loaded {
    opacity: 0.3;
    transition: opacity 0.5s ease-in-out;
}

.notability-preview {
    display: flex;
    align-items: center;
}

.notability-preview .ipad {
    width: 64%;
    transition: opacity 0.5s ease-in-out,
                width 0.6s cubic-bezier(0.7, -0.4, 0.4, 1.4),
                transform 0.6s cubic-bezier(0.7, -0.4, 0.4, 1.4);
}

.notability-preview .ipad-prototypes:hover {
    opacity: 0.9;
}

.notability-preview .ipad-prototypes:hover .first {
    transform: translateY(-160px);
}

.notability-preview .ipad-prototypes:hover .third {
    transform: translateY(160px);
}

.notability-preview .ipad-prototypes:hover .second {
    width: 84%;
}


/* Images & Videos */

.image-prototypes {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: -2;
}

.video-prototype video {
    z-index: -2;
    width: 100%;
    position: absolute;
    /* border-radius: 24px; */
    box-sizing: padding-box;
    /* aspect-ratio: 3.25/3; */
    /* height: 412px; */
    /* height: 100%; */
    /* opacity: 0.8; */
}

/* In Progress */

.in-progress {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.56); /* 64% white background */
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    border: 0.5px solid #314321;
    padding: 16px;
    border-radius: 100px;
    color: #314321;
    opacity: 0;
    transition: opacity 0.6s ease-in-out;
    display: flex;
    align-items: center;
    gap: 4px;
}

.preview:hover .in-progress {
    opacity: 1;
} 

/* ARTCAMP */

.image-prototypes .artcamp img {
    border-radius: 0 4.28% 4.28% 4.28%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0.6;
    transition: opacity 0.5s ease-in-out;
}

.preview-image-container:hover .artcamp img {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

/* .artcamp.layer1 {
    mix-blend-mode: screen;
    transform: translate3d(0, 0, 0);
    opacity: 1;
}

.artcamp.layer1 > .second {
    opacity: 0;
    mix-blend-mode: color-dodge;
    transition: opacity 0.5s ease-in-out;
} 

.artcamp.layer2 {
    mix-blend-mode: overlay;
    filter: blur(4px);
}

.artcamp.layer2 > .second {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.image-prototypes:hover .artcamp.layer1 > .second {
    opacity: 1;
}

.image-prototypes:hover .artcamp.layer2 > .second {
    opacity: 1;
} */

/* WDC */

.image-prototypes > .wrek img {
    position: absolute;
    mix-blend-mode: multiply;
    top: 50%;
    left: 50%;
    width: 64%;
    opacity: 0.3;
    transform: 
    scale(0.70)
    rotateY(180deg)
    translate(70%, -70%);
    transition: opacity 1s ease-in-out, transform 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}


.preview-image-container:hover .wrek img {
    mix-blend-mode: normal;
    opacity: 0.8;
    transform:
    scale(1)
    rotateY(0deg)
    translate(-50%, -50%);
}


/* 8-Ball */
.image-prototypes > .eight-ball img {
    border-radius: 0 4.28% 4.28% 4.28%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: opacity 0.3s ease-in-out;
    height: 100%;
}

.image-prototypes--loaded > .eight-ball img {
    opacity: 0.3;
    transition: opacity 0.5s ease-in-out;
}

.preview-image-container:hover .eight-ball img {
    opacity: 0.6;
}

/* Summer 2023 */
.video-prototype > video.summer-2023 {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.video-prototype--loaded > video.summer-2023 {
    opacity: 0.3;
    transition: opacity 0.5s ease-in-out;
}

.preview-image-container:hover video.summer-2023 {
    opacity: 0.8;
    transition: opacity 0.5s ease-in-out;
}

/*  */

/* Responsive layouts */

/* Touchscreens and phones */
@media (hover: none) {
    .mobile-prototypes .first,
    .mobile-prototypes .second {
        transform: 
        translateY(0) !important;
        opacity: 0.9 !important;
    }

    .in-progress {
        opacity: 0.6 !important;
    }

    .artcamp.layer1 > .second {
        opacity: 1 !important;
    }
    
    .artcamp.layer2 > .second {
        opacity: 1 !important;
    }

    .image-prototypes > .wrek img {
        mix-blend-mode: normal !important;
        opacity: 0.8 !important;
        transform:
        scale(1) 
        rotateY(0deg)
        translate(-50%, -50%) !important;
    }
}